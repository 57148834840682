.hero-container{
    background: linear-gradient(
        to top right, 
        white, 
        rgb(201, 201, 201), 
        rgb(63, 63, 63), 
        black);
    height: 100%;
    width: 100%;

        
}

.hero-inner-container{

    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    height: 100vh;

}

.hero-text-wrapper{
    float: left;
    margin-top: 12%;
    margin-left: 5%;
    /* background-color: green; */
}



@media (max-width: 600px) {
    .placeholder-for-mobile {
        height: calc(100vh - 450px);
        width: 100%;
    }
    .hero-text-wrapper {
        margin-top: auto;
        margin-left: 15%;
    }
    
}
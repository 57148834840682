.navigate{
    flex: 0.5;
}

.VerticalNav {
    height: 100%;
    padding-top: 65px;
    position: fixed;
    z-index: 1;
}
